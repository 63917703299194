import "core-js/modules/es6.array.map.js";
export default defineComponent({
  props: {
    impact: {
      type: Object,
      required: true
    }
  },
  setup: function setup(props) {
    var items = computed(function () {
      return props.impact.irisCategories.map(function (e) {
        return e.label;
      });
    });
    return {
      items: items
    };
  },
  computed: {}
});